import './App.css';

function App() {
  return (
    <div className='App'>
      <p>This is work in progress.</p>
    </div>
  );
}

export default App;
